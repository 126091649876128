import { entryPointUriPathToPermissionKeys } from '@commercetools-frontend/application-shell/ssr';

let entryPointUriPath;
try {
  entryPointUriPath =
    process?.env?.ENTRY_POINT ??
    process?.env?.entryPointUriPath ??
    window.app.entryPointUriPath;
} catch (e) {}
try {
  if (!entryPointUriPath) {
    entryPointUriPath =
      process?.env?.entryPointUriPath ?? window.app.entryPointUriPath;
  }
} catch (e) {}
try {
  if (!entryPointUriPath) {
    entryPointUriPath = window.app.entryPointUriPath;
  }
} catch (e) {}

export { entryPointUriPath };

export const PERMISSIONS = entryPointUriPathToPermissionKeys(entryPointUriPath);

export const FEATURE_FLAGS = {};

export const ENDPOINTS = {
  graphql: 'https://mc-api.us-central1.gcp.commercetools.com',
};

export const FEATURE_FLAGS_METADATA_KEYS = {
  dev: 'feature_flags_metadata_dev',
  qa: 'feature_flags_metadata_qa',
  stage: 'feature_flags_metadata_stage',
  prod: 'feature_flags_metadata_prod',
};

export const FEATURE_FLAGS_CONTAINERS = {
  dev: 'feature_flags_dev',
  qa: 'feature_flags_qa',
  stage: 'feature_flags_stage',
  prod: 'feature_flags_prod',
};

export const JP_PROJECT_KEYS = {
  dev: 'ecomm-dev',
  qa: 'globalecomm-qa',
  stage: 'globalecomm-stage',
  prod: 'globalecomm-prod',
};

export const EU_PROJECT_KEYS = {
  dev: 'eu-ecomm-dev',
  qa: 'eu-gecomm-qa',
  stage: 'eu-gecomm-stage',
  prod: 'eu-gecomm-prod',
};

export const NAM_US_META_PROJECT_KEYS = {
  dev: 'nam-gecomm-dev',
  qa: 'nam-gecomm-qa',
  stage: 'nam-gecomm-stage',
  prod: 'nam-gecomm-prod',
};

export const getSearchCustomersEndpoint = ({
  endpoint,
  selectValue,
  value,
  limit,
  showOnlyPracs,
}) => {
  if (showOnlyPracs) {
    return `${endpoint}/practitioner-patient/ctp/customers/search/${selectValue}/${value}?type=practitioner&condition=startswith${
      limit ? `&limit=${limit}` : ''
    }`;
  }
  return `${endpoint}/practitioner-patient/ctp/customers/search/${selectValue}/${value}?condition=startswith${
    limit ? `&limit=${limit}` : ''
  }`;
};
