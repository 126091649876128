import React from 'react';
import ReactDOM from 'react-dom';
import EntryPoint from './components/entry-point';

const env = process.env.NODE_ENV;
if (env === 'development') {
  localStorage.removeItem('activeProjectKey');
}

ReactDOM.render(<EntryPoint />, document.getElementById('app'));
